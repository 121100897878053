






























































import Vue, { PropType } from 'vue';
import { OAuthIdentityProvider, OAuthSupport } from 'vaultifier';
import { getTranslation } from '../utils';

export interface Data {
  appKey: string,
  appSecret: string,
  scope?: string,
}

export default Vue.extend({
  props: {
    scopes: Array as PropType<string[] | undefined>,
    identityProviders: Array as PropType<(OAuthSupport | OAuthIdentityProvider)[] | undefined>,
  },
  data: (): Data => ({
    appKey: '',
    appSecret: '',
    scope: undefined,
  }),
  created() {
    if (this.scopes && this.scopes.length > 0)
      this.scope = this.scopes[0];
  },
  methods: {
    submitForm(event: Event) {
      event.preventDefault();

      this.$emit('login', {
        appKey: this.appKey,
        appSecret: this.appSecret,
        scope: this.scope,
      } as Data);
    },
    getIdentityProviderTitle(identityProvider: OAuthIdentityProvider) {
      // 'OAuth' is the default string for OYD-internal authorization code
      return getTranslation(identityProvider.title ?? 'OAuth');
    }
  },
  computed: {
    scopeOptions() {
      return this.scopes?.map((x) => ({
        text: x,
        value: x,
      }));
    }
  }
})
