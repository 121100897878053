
















import Vue from 'vue'
export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    click() {
      this.$emit('click', ...arguments);
    }
  },
  computed: {
    computedType() {
      return this.type === 'refresh' ? 'outline-primary' : this.type;
    }
  }
})
