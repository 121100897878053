








import Vue from 'vue'
export default Vue.extend({
  props: {
    variant: {
      default: 'dark',
    },
    small: {
      default: false,
    }
  }
});
