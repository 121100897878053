



























































import Vue, { PropType } from 'vue';

import { VaultItem, VaultMinMeta, VaultPostItem } from 'vaultifier';
import RawData from './RawData.vue';

import FormEditView from './FormEditView.vue';

import { ActionType } from '@/store/action-type';

interface Data {
  isSaving: boolean;
  activeTabIndex: number;
  saveMessage?: string;
}

export default Vue.extend({
  props: {
    item: Object as PropType<VaultItem>,
    showRawView: {
      default: true,
      type: Boolean as PropType<boolean>,
    },
  },
  data: (): Data => ({
    isSaving: false,
    activeTabIndex: 0,
    saveMessage: undefined,
  }),
  components: {
    RawData,
    FormEditView,
  },
  computed: {
    schemaDri(): string | undefined {
      return this.item.meta.schema;
    },
    hasSchema(): boolean {
      return !!this.schemaDri;
    },
  },
  methods: {
    async saveVaultItem(item: VaultPostItem, onComplete?: () => void) {
      this.saveMessage = undefined;
      this.isSaving = true;

      try {
        await this.$store.dispatch(ActionType.UPDATE_VAULT_ITEM, item);
      } catch (e: any) {
        console.error(e);
        this.saveMessage = e.message ?? 'Could not save item';
      }

      this.isSaving = false;

      if (onComplete)
        // indicate saving is complete
        onComplete();
    },
  },
})
